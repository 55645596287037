.bm-menu .menu-item {
  cursor: pointer;
  font-size: 1.2em;
  margin: 1em 0;
}

.bm-menu .menu-items {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
}
.bm-menu .menu-items img {
  width: 100%;
  height: auto;
}
