.resume-item {
  display: flex;
  margin: 0.5em 0.5em 1.5em;
  width: 350px;
}

.resume-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 6px;
}

.resume-text .role {
  margin-bottom: 8px;
}

.resume-item img {
  margin: 0 1em 0 0;
  height: 150px;
  width: auto;
}

#resume h2 {
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #222;
}

.resume-group {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
}

.accordion {
  border: none;
}

.accordion__button {
  display: flex;
  align-items: center;
}

.accordion__title {
  background-color: white;
}
.accordion__title:hover {
  background-color: #f9f9f9;
}

.accordion [aria-expanded="true"] h2 img {
  transform: rotate(-180deg);
}

.accordion h2 img {
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

@media only screen and (max-width: 767px) {
  .resume-container {
    flex-direction: column;
  }

  .resume-container img {
    height: 150px;
    width: auto;
  }
}
