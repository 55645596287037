#header {
  display: flex;
  width: 100%;
  height: 68px;
  padding: 0 1em;
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  box-shadow: 0 3px 16px 0px #222;
  z-index: 2;
}

#header img {
  height: 68px;
  width: auto;
  margin: 3px 4px;
  cursor: pointer;
}

.header-left {
  display: flex;
  align-items: center;
}

.header-right {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.header-blurb {
  display: flex;
  flex-direction: column;
}

.header-item {
  cursor: pointer;
  font-size: 1em;
  margin: 1em;
}

.header-item:hover {
  color: #8fcdf5;
}

.header img:hover {
  filter: brightness(50%);
}

/* For screens smaller than 767px */
@media only screen and (max-width: 767px) {
  .header-item {
    display: none;
  }
  .header-blurb {
    display: none;
  }
}
/* For screens larger than 767px */
@media only screen and (min-width: 767px) {
  .bm-burger-button {
    display: none;
  }
}
