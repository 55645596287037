#page-wrap {
  display: flex;
}

#title {
  margin: 0.25em 0;
}

.blurb {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 4em;
}

#main-content {
  background-color: #fff;
  color: #222;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.link-icon {
  margin: 4px 0 0 10px;
  width: 25px;
}

.content-section {
  padding: 18px 36px;
  max-width: 1300px;
  width: 100%;
}

/* Burger Menu */
/* Position and sizing of burger button */
.bm-burger-button {
  margin-top: 16px;
  position: relative;
  width: 36px;
  height: 30px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #fafafa;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 36px;
  width: 36px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background-color: #131313;
  padding: 2em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}

/* Media Queries */
/* For screens smaller than 767px */
@media only screen and (max-width: 767px) {
  .content-section {
    padding: 10px 18px;
  }
}
/* For screens larger than 767px */
@media only screen and (min-width: 767px) {
  .bm-burger-button {
    display: none;
  }
}
