#about h1 {
  color: white;
  font-size: 8em;
  font-weight: bold;
}

img {
  max-width: 100vw;
  max-height: 80vh;
}

.swiper {
  margin-top: 75px;
}

#overlay {
  width: 100%;
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 1) 10%,
      transparent 10% 90%
    ),
    linear-gradient(to left, rgba(255, 255, 255, 1) 10%, transparent 10% 90%);
}

/* For screens smaller than 767px */
@media only screen and (max-width: 767px) {
  #overlay {
    display: none;
  }
}
