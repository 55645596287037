.iframe-container {
  overflow: hidden;
  /* // Calculated from the aspect ration of the content (in case of 16:9 it is 9/16= 0.5625) */
  padding-top: 56.25%;
  position: relative;
  margin-bottom: 4px;
}

.iframe-container iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
