#contact {
  margin-bottom: 2em;
}
#contact .sections {
  display: flex;
  flex-wrap: wrap;
}

#contact .sections > div {
  flex: 1;
  padding: 0.5em 1em;
}

#contact .casual-section {
  flex: 1;
}
#contact .professional-section {
  flex: 1;
}

#contact form {
  display: flex;
  flex-direction: column;
}

#contact input,
textarea {
  width: 100%;
  padding: 12px;
  margin: 6px 0 24px;
  border: 1px solid #ccc;
  background: #fafafa;
  color: #000;
  line-height: normal;
  box-sizing: border-box;
  border-radius: 2px;
  resize: vertical;
}

#contact input:focus,
textarea:focus {
  background: #fff;
  -webkit-transition: background 0.1s ease-in;
  -moz-transition: background 0.1s ease-in;
  -ms-transition: background 0.1s ease-in;
  -o-transition: background 0.1s ease-in;
  transition: background 0.1s ease-in;
  outline: none;
}

#contact button {
  border-width: 2px;
  border-style: solid;
  border-color: #222;
  -webkit-transition: 0.1s background-color linear, 0.1s color linear;
  -moz-transition: 0.1s background-color linear, 0.1s color linear;
  -o-transition: 0.1s background-color linear, 0.1s color linear;
  transition: 0.1s background-color linear, 0.1s color linear;
  background-color: transparent;
  color: #000;
  height: 46px;
  width: 130px;
  align-self: center;
  margin: 1em;
}

#contact button:hover {
  background-color: #000;
  color: #fff;
}

#contact .professional-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.professional-section img {
  margin: 1em;
  width: 200px;
}
